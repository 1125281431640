.tree-title{
    color: #4C4C66;
}
.custom-bg{
    background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);
    box-shadow: -10px 48.5967px 140px rgba(126, 123, 160, 0.2);
}
.tree-view{
    .min-w-42{
        min-width: 42px;
    }
    &>ul>li{
        font-weight: 700;
        text-transform: capitalize;
        &:not(:last-of-type){
            margin-bottom: 10px;
        }
        ul{
            position: relative;
            margin-top: 6px;
            &:before{
                content:"";
                width: 1px;
                height: 100%;
                border-left: 1px dashed #9b97b8;
                position: absolute;
                top: 0;
                }
            li{
                font-weight: normal;
                position: relative;
                padding-left: 24px;
                text-transform: capitalize;
                &:not(:last-of-type){
                    margin-bottom: 5px;
                }
                &:before{
                    content:"";
                    width: 18px;
                    height: 1px;
                    float: left;
                    border-bottom: 1px dashed #9b97b8;
                    margin-top: 12px;
                    position: absolute;
                    left: 0;
                }
            }
        }
        .active-list{
            color: #00A39A;
            .view-key{
                color: #000;
            }
        }
        .union-icon{
            margin-top: -1px;
        }
        .device-id{
            max-height: 140px;
        }
    }
    .door-val{
        font-size: 10px;
    }
    .max-w-80{
        max-width: 80%;
    }
    .max-w-50{
        max-width: 50%;
    }
}