.sidebar{
  width: 348px;
  @media(max-width: 767px){
      width: 100%;
  }
}
.location-block{
  margin-left: 348px;
  &:after{
      content: "";
      display: block;
      clear: both;
      visibility: hidden;
  }
  @media(max-width: 767px){
      margin-left: 0;
      clear: both;
  }
}
.dropdown-list-location {
  min-width: 110px;
  color: #4C4C66;
  li {
      cursor: pointer;
      font-weight: 500;
      @media(min-width: 768px){
          &:hover {
              background-color: #EAEDF3;
          }
      }
      &.has-submenu{
          &:after{
              content: "";
              width: 15px;
              height: 100%;
              background: url(../../assets/images/icons/right-arrow.svg) no-repeat left center;
              position: absolute;
              top: 0;
              right: 0;
              @media(max-width: 767px){
                  background: none;
              }
          }
          &>span{
              @media(max-width: 767px){
                  display: block;
                  background-color: #737373;
                  color: #fff;
                  padding: 2px 11px;
                  text-transform: uppercase;
              }
          }
          .dropdown-list-sub{
              min-width: 110px;
              @media(min-width: 768px){
                  display: none;
              }
              @media(max-width: 767px){
                  position: static;
              }
          }
          @media(min-width: 768px){
              &:hover{
                  &>.dropdown-list-sub{
                      display: block;
                  }
              }
          }
      }
  }
  @media(max-width: 767px){
      right: 0;
      min-width: 160px;
  }
}

.device-tab-wrapper{
    .device-tab-title{
        color: #383839;
        border-bottom: 2px solid transparent;
        top: 1px;
        cursor: pointer;
        transition: all 0.2s linear;
        &.active-tab {
            border-color: #00A49F;
            color: #00A49F;
        }
        &:hover{
            border-color: #00A49F;
            color: #00A49F;
        }
    }
    .unassigned-device{
        .filter-wrapper{
            button{
                height: 34px;
            }
        }
    }
    .filter-dropdown{
        .filter-dropdown-title{
            min-width: 120px;
            max-width: 160px;
            color: #2B3646;
            &:after{
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                height: 100%;
                background: url(../../assets/images/icons/dropdown-icon.svg) no-repeat center center;
            }
        }
        .filter-dropdown-ul{
            color: #2B3646;
            li{
                &:hover{
                    background-color: #EAEDF3;
                }
            }
        }
    }
    .device-action-drop{
        width: 120px;
        .device-action-drop-title{
            cursor: pointer;
            &:after{
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                height: 100%;
                background: url(../../assets/images/icons/dropdown-icon.svg) no-repeat center center;
            }
        }
        .action-dropdown-ul{
            color: #2B3646;
            z-index: 99;
            li{
                &:hover{
                    background-color: #EAEDF3;
                }
            }
        }
    }
    .search-filter{
        @media(max-width: 991px){
            width: 100%;
        }
        input{
            height: 34px;
            min-width: 100%;
            @media(min-width: 992px){
                min-width: 320px;
            }
            &:focus{
                border: 1px solid #2da39a;
            }
        }
    }
    .device-table-wrapper{
        background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
        box-shadow: -13px 48.5967px 140px rgba(126, 123, 160, 0.2);
        thead{
            background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
            th{
                font-size: 13px;
                padding: 15px 20px 8px;
            }
        }
        tbody{
            tr{
                border-top: 1px solid #EEE8E7;
                font-size: 14px;
                font-weight: 400;
                &:nth-of-type(even){
                    background: #F9F8F8;
                }
                td{
                    padding: 8px 20px;
                    color: #383839;
                }
                &:nth-last-of-type(1){
                    .action-dropdown-ul{
                        top: auto;
                        bottom: 20px;
                    }
                }
            }
        }
    }
    .pagination-wrappper{
        ul{
            li{
                font-size: 15px;
                color: #4a4a4a80;
                margin: 0px 6px;
                cursor: pointer;
                &.has-bg{
                    background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
                    box-shadow: -13px 48.5967px 140px rgba(126, 123, 160, 0.2);
                    padding: 4px;
                    width: 22px;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    top: -1px;
                }
            }
            li.active-page{
                color: #383839;
            }
        }
    }
}