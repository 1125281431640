.popup-wrapper{
    background: rgba(0,0,0,0.5);
    z-index: 999;   
    .popup-container{
        max-width: 430px;
        z-index: 1234;
        padding: 0px 15px;
        &>div.bg-white{
            box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.5);
        }
        input:focus{
            border: 1px solid #2da39a;
        }
        .popup-btn{
            button{
                box-shadow: 0px 6px 34px rgba(26, 19, 70, 0.12);
                min-width: 140px;
            }
        }
    }
}
.assign-device-select{
    .select__control{
        min-height: 3.5rem;
        border-radius: 0.5rem;
        border-color: rgba(209, 213, 219, 0.8) !important;
        font-size: 15px;
        &:focus, &:hover{
            outline: none !important;
            box-shadow: none !important;
            border-color: rgba(209, 213, 219, 0.8) !important;
        }
        &--is-focused{
            outline: none !important;
            box-shadow: none !important;
            border-color: rgba(209, 213, 219, 0.8) !important;
        }
    }
    .select__value-container{
        &:focus, &:hover{
            outline: none !important;
            box-shadow: none;
        } 
    }
    .select__option{
        padding: 6px 12px;
        color: #012040;
        font-size: 13px;
        &:hover{
            background: #F8F8F8; 
            font-weight: 500;
        }
        &--is-focused{
            background: #F8F8F8;
            font-weight: 500;
        }
    }
    .select__menu{
        margin-top: 0px;
        border-radius: 8px;
    }
    .select__multi-value{
        background: #F5F5F5;
        border: 0.81px solid #C2C2C2;
        border-radius: 3px;
        padding: 0px 4px;
        &__label{
            font-size: 14px;
        }
        &__remove{
            width: 22px;
            height: 26px;
            cursor: pointer;
            background: url(../../../assets/images/icons/list-remover.svg) no-repeat center center;
            svg{
                display: none;
            }
            &:hover{
                background-color: transparent;
            }
        }
    }
}