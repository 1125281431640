
.dropdown-list {
    min-width: 110px;
    color: #4C4C66;
    li {
        cursor: pointer;
        font-weight: 500;
        &:hover {
            background-color: #EAEDF3;
        }
        &.has-submenu{
            &:after{
                content: "";
                width: 15px;
                height: 100%;
                background: url(../../assets/images/icons/right-arrow.svg) no-repeat left center;
                position: absolute;
                top: 0;
                right: 0;
            }
            .dropdown-list{
                display: none;
            }
            &:hover{
                &>.dropdown-list{
                    display: block;
                }
            }
        }
    }
}
.users-table-wrapper{
    .dropdown-list {
        min-width: 120px;
    }
    .user-table-title{
        color: #2B3646;
    }
    .user-info{
        p{
        color: #343435;
        }
        span{
            color: #4C4C66;
            font-size: 13px;
        }
    }
    .role-list{
        max-width: 180px;
        li{
            font-size: 12px;
        }
    }
}

.dropdown-title-user{
    color: #2B3646;
    &:after{
        content: "";
        width: 30px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: url(../../assets/images/icons/select-list-arrow.svg) no-repeat center center rgba(244, 247, 249, 0.4);
        border-left: 1px solid #DCDCDC;
    }
}
@media(max-width:767px){
    .bottom-mob-btn{
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 11.03%, #FFFFFF 100%);
        z-index: 40;
        text-align: center;
    }
}