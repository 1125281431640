.popup-wrapper{
  background: rgba(0,0,0,0.5);
  z-index: 999;   
  .popup-container{
      max-width: 430px;
      z-index: 12345;
      padding: 0px 15px;
      input:focus {
        border: 1px solid #2da39a;
      }
      &.mw-594{
          max-width: 594px;
      }
      &.mw-480{
          max-width: 480px;
      }
      &>div.bg-white{
          box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.5);
      }
      .chkbox{
          span{
              border-radius: 2px;
          }
          input:checked+span{
              background: url(../../../../assets/images/icons/checkmark.svg) no-repeat center center #00A49F;
              background-size: 9px;
              border-color: #00A49F;
          }
      }
      .placeholder-txt{
          color: #B4B4B4;
      }
      .text-dark-grey{
          color: #383839;
      }
  }
}

.dropdown-box{
  box-shadow: 0px 6px 10px rgba(0,0,0,0.5);
  z-index: 999;
  &.position-top{
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      bottom: 55px;
  }
  &.mw-276{
      max-width: 276px;
  }
  .dropdown-list{
      &>p{
          background: #F5F5F5;
          color: #3D4455;
      }
      ul{
          max-height: 160px;
          overflow-y: auto;
          &::-webkit-scrollbar {
              width: 6px;
          }
          &::-webkit-scrollbar-track {
              background: #c4c4c4;
              border-radius: 14px; 
          }
          &::-webkit-scrollbar-thumb {
              background: #717171;
              border-radius: 14px;
          }
          &::-webkit-scrollbar-thumb:hover {
              background: #555; 
          }
      }
      li{
          width: 100%;
          float: left;
          font-size: 14px;
          color: #3D4455;
          cursor: pointer;
          padding: 4px 10px;
          &:not(:last-of-type){
              margin-bottom: 2px;
          }
          &:hover{
              background: #f5f5f5;
          }
      }
  }
}
.dropdown-title{
  &:after{
      content: "";
      width: 52px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: url(../../../../assets/images/icons/right-arrow.svg) no-repeat center center rgba(244, 247, 249, 0.4);
      border-left: 1px solid #DCDCDC;
  }
}