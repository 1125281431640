.location-box{
  background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);
  box-shadow: -10px 48.5967px 140px rgba(126, 123, 160, 0.2);
  .opening-anchor{
      letter-spacing: -0.57px;
      color: #089A96;
      background: rgba(8, 154, 150, 0.06);
      border: 1px solid #089A96;
  }
  .assign-anchor{
      min-width: 75px;
      background: rgba(33, 175, 255, 0.06);
      border: 1px solid #026EED;
      color: #026EED;
      letter-spacing: -0.57px;
  }
}

.building-list-wrapper{
  .building-list-inner{
      min-width: 840px;
  }
  .building-list{
      background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%);
      border-color: #DBDBDB;
  }
  .w-p-18{
      width: 18%;
      flex-shrink: 0;
  }
  .w-p-26{
      width: 26%;
      flex-shrink: 0;
  }
  .w-p-13{
      width: 13%;
      flex-shrink: 0;
  }
  .w-p-16{
      width: 16%;
      flex-shrink: 0;
  }
  .w-p-22{
      width: 22%;
      flex-shrink: 0;
  }
}
.online:before{
  content: "";
  width: 9px;
  height: 9px;
  background: #04C017;
  display: inline-block;
  border-radius: 9px;
  margin-right: 3px;
}
.offline:before{
  content: "";
  width: 9px;
  height: 9px;
  background: #F50F0F;
  display: inline-block;
  border-radius: 9px;
  margin-right: 3px;
}
.more-assign{
  color: #026EED;
  margin-left: 4px;
  font-size: 12px;
}

.door-details-wrapper{
  .open-anchor{
      background: rgba(33, 175, 255, 0.06);
      border: 1px solid #026EED;
      color: #026EED;
      letter-spacing: -0.57px;
      font-size: 10px;
      padding-top: 2px;
      padding-bottom: 2px;
  }
}
.device-table{
  background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
  table{
      tr{
          border: 1px solid #EEE8E7; 
      }
      thead{
          th{
              font-weight: 500;
              font-size: 13px;
              color: #1A1A1A;
              padding: 16px 15px 8px;
              text-align: left;
              white-space: nowrap;
          }
      }
      tbody{
          tr:nth-of-type(odd){
              background: #F9F8F8;
          }
          td{
              font-weight: normal;
              font-size: 14px;
              color: #383839;
              padding: 6px 15px;
          }
      }
  }
}

.log-wrapper{
  background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
  box-shadow: -13px 48.5967px 140px rgba(126, 123, 160, 0.2);
  .log-tab-title{
      color: #383839;
      font-size: 13px;
      border-bottom: 2px solid transparent;
      top: 1px;
      cursor: pointer;
      transition: all 0.2s linear;
      &:hover{
          border-color: #00A49F;
          color: #00A49F;
      }
      &.active-tab{
          border-color: #00A49F;
          color: #00A49F;
      }
  }
  .log-list{
      .has-dot-center{
          &:before{
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              top: 50%;
              left: 0;
              margin-left: -4px;
              margin-top: -4px;
              background: #4C4C66;
              border-radius: 100%;
          }
      }
      &.status-opened{
          .has-dot-center{
              &:before{background: #026EED;}
          }
      }
      &.status-closed{
          .has-dot-center{
              &:before{background: #FF7F08;}
          }
      }
      &.status-maintenance{
          .has-dot-center{
              &>p:before{
                  content: "";
                  width: 10px;
                  height: 10px;
                  background: url(../../assets/images/icons/union.svg) no-repeat center center;
                  display: inline-block;
                  margin-right: 6px;
              }
          }  
      }
  }
  .log-content-wrapper{
      color: #383839;
      .log-scroll{
          max-height: 226px;
          overflow-y: auto;
          &::-webkit-scrollbar {
              width: 6px;
          }
          &::-webkit-scrollbar-track {
              background: #E7E7E7;
          }
          &::-webkit-scrollbar-thumb {
              background: #c4c4c4;
          }
          &::-webkit-scrollbar-thumb:hover {
              background: #555; 
          }
      }
  }
}