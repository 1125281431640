.header{
    .text-color-title{
        &:hover{
            color: #00A49F !important;
        }
        &.active-menu{
            color: #00A49F !important;
            font-weight: 700;
        }
    }
    .notification-status{
        font-size: 9px;
        min-width: 16px;
        height: 16px;
    }
}