.frm-container {
  label {
    font-size: 15px;
  }
  input {
    &:focus {
      border-color: #06b9b4;
    }
  }
  .visible-icon {
    width: 48px;
    cursor: pointer;
    background: url(../../assets/images/icons/eye-icon.svg) no-repeat center
      center;
    &.active-icon {
      background: url(../../assets/images/icons/eye-icon-active.svg)
        no-repeat center center;
    }
  }
  .email-icon {
    background: url(../../assets/images/icons/email-icon.svg) no-repeat 14px
      center;
  }
  .pwd-icon {
    background: url(../../assets/images/icons/key-icon.svg) no-repeat 14px
      center;
  }
  .button-wrapper {
    button {
      &.bg-primary:hover {
        background-color: #06b9b4;
      }
      &.border-primary:hover {
        background-color: #06b9b4;
        color: #fff;
      }
    }
  }
}
