.sidebar{
    width: 348px;
    @media(max-width: 767px){
        width: 100%;
    }
}
.location-block{
    margin-left: 348px;
    &:after{
        content: "";
        display: block;
        clear: both;
        visibility: hidden;
    }
    @media(max-width: 767px){
        margin-left: 0;
        clear: both;
    }
}
@media(max-width:767px){
    .bottom-mob-btn{
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 11.03%, #FFFFFF 100%);
        z-index: 40;
        text-align: center;
    }
}

.device-table-wrapper{
    background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
    box-shadow: -13px 48.5967px 140px rgba(126, 123, 160, 0.2);
    thead{
        background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
        th{
            font-size: 13px;
            padding: 15px 20px 8px;
        }
    }
    tbody{
        tr{
            border-top: 1px solid #EEE8E7;
            font-size: 14px;
            font-weight: 400;
            &:nth-of-type(even){
                background: #F9F8F8;
            }
            td{
                padding: 8px 20px;
                color: #383839;
            }
            &:nth-last-of-type(1){
                .action-dropdown-ul{
                    top: auto;
                    bottom: 20px;
                }
            }
        }
    }
}

.pagination-wrappper{
    ul{
        li{
            font-size: 15px;
            color: #4a4a4a80;
            margin: 0px 6px;
            cursor: pointer;
            &.has-bg{
                background: linear-gradient(198.7deg, rgba(255, 255, 255, 0.56) 30.95%, rgba(248, 248, 255, 0.71) 87.42%, rgba(255, 255, 255, 0.59) 144.7%);
                box-shadow: -13px 48.5967px 140px rgba(126, 123, 160, 0.2);
                padding: 4px;
                width: 22px;
                display: flex;
                justify-content: center;
                position: relative;
                top: -1px;
            }
        }
        li.active-page{
            color: #383839;
        }
    }
}

.device-action-drop{
    width: 120px;
    .device-action-drop-title{
        cursor: pointer;
        &:after{
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            height: 100%;
            background: url(../../assets/images/icons/dropdown-icon.svg) no-repeat center center;
        }
    }
    .action-dropdown-ul{
        color: #2B3646;
        z-index: 99;
        li{
            &:hover{
                background-color: #EAEDF3;
            }
        }
    }
}