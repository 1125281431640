.popup-wrapper{
    background: rgba(0,0,0,0.5);
    z-index: 999;   
    .popup-container{
        max-width: 430px;
        z-index: 12345;
        padding: 0px 15px;
        &>div.bg-white{
            box-shadow: 0px 0px 4px rgba(185, 185, 185, 0.5);
        }
        input:focus{
            border: 1px solid #2da39a;
        }
        .popup-btn{
            button{
                box-shadow: 0px 6px 34px rgba(26, 19, 70, 0.12);
                min-width: 140px;
            }
        }
    }
}